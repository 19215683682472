import { Grid ,Card, CardContent} from '@material-ui/core'
import React from 'react'; 
import { useServices, components, useTranslation,constants} from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TermsOfUse from 'src/constants/locale/key/TermsOfUse'


const {
  card: {
    CngSimpleCardHeader
  },
  form: {
    field: {
    },
  },
  table: {
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL, LIKE }
} = constants

function ExternalTermsOfUse ({ className, ...rest }) { 

  const { translate } = useTranslation([Namespace.TERMS_OF_USE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let changePassword = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.TERMS_OF_USE
    )

    let general = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.GENERAL
    )

    let generalDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.GENERAL_DETAILS1
    )

    let generalDetails2 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.GENERAL_DETAILS2
    )

    let terminology = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.TERMINOLOGY
    )

    let terminologyDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.TERMINOLOGY_DETAILS1
    )

    let disclaimerAndLiability = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.DISCLAIMER_AND_LIABILITY
    )

    let disclaimerAndLiabilityDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.DISCLAIMER_AND_LIABILITY_DETAILS1
    )


    let disclaimerAndLiabilityDetails2 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.DISCLAIMER_AND_LIABILITY_DETAILS2
    )

    let disclaimerAndLiabilityDetails3 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.DISCLAIMER_AND_LIABILITY_DETAILS3
    )

    let disclaimerAndLiabilityDetails4 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.DISCLAIMER_AND_LIABILITY_DETAILS4
    )

    let exceptionToDisclaimer = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.EXCEPTION_TO_DISCLAIMER
    )

    let exceptionToDisclaimerDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.EXCEPTION_TO_DISCLAIMER_DETAILS1
    )

    let copyright = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.COPYRIGHT
    )

    let copyrightDetails1  = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.COPYRIGHT_DETAILS1
    )

    let copyrightDetails2 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.COPYRIGHT_DETAILS2
    )

    let copyrightDetails3 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.COPYRIGHT_DETAILS3
    )

    let trademarks = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.TRADEMARKS
    )

    let trademarksDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.TRADEMARKS_DETAILS1
    )

    let restrictedUse = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.RESTRICTED_USE
    )

    let restrictedUseDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.RESTRICTED_USE_DETAILS1
    )

    let linkedWebSites = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.LINKED_WEB_SITES
    )


    let linkedWebSitesDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.LINKED_WEB_SITES_DETAILS1
    )


    let linkedWebSitesDetails2 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.LINKED_WEB_SITES_DETAILS2
    )

    let linkedWebSitesDetails3 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.linkedWebSitesDetails3
    )

    let linksFromOtherWebSites = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.LINKS_FROM_OTHER_WEBSITES
    )

    let linksFromOtherWebSitesDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.LINKS_FROM_OTHER_WEBSITES_DETAILS1
    )

    let availabilityAndTerminationOfAccess = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.AVAILABILITY_AND_TERMINATION_OF_ACCESS
    )

    let availabilityAndTerminationOfAccessDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.AVAILABILITY_AND_TERMINATION_OF_ACCESS_DETAILS1
    )

    let availabilityAndTerminationOfAccessDetails2 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.AVAILABILITY_AND_TERMINATION_OF_ACCESS_DETAILS2
    )

    let governingLaw = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.GOVERNING_LAW
    )

    let governingLawDetails1 = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.GOVERNING_LAW_DETAILS1
    )

    
    let version = translate(
      Namespace.TERMS_OF_USE,
      TermsOfUse.VERSION
    )
      

    return {
      changePassword,
      general,
      generalDetails1,
      generalDetails2,
      terminology,
      terminologyDetails1,
      disclaimerAndLiability,
      disclaimerAndLiabilityDetails1,
      disclaimerAndLiabilityDetails2,
      disclaimerAndLiabilityDetails3,
      disclaimerAndLiabilityDetails4,
      exceptionToDisclaimer,
      exceptionToDisclaimerDetails1,
      copyright,
      copyrightDetails1,
      copyrightDetails2,
      copyrightDetails3,
      trademarks,
      trademarksDetails1,
      restrictedUse,
      restrictedUseDetails1,
      linkedWebSites,
      linkedWebSitesDetails1,
      linkedWebSitesDetails2,
      linksFromOtherWebSites,
      linksFromOtherWebSitesDetails1,
      availabilityAndTerminationOfAccess,
      availabilityAndTerminationOfAccessDetails1,
      availabilityAndTerminationOfAccessDetails2,
      governingLaw,
      governingLawDetails1,
      version

    }
  }
  const { createRecord,securedSendRequest,fetchRecords } = useServices()
  
  return ( 
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card> 
          <CngSimpleCardHeader title={translatedTextsObject.changePassword}/>
          <br></br>
            <CardContent spacing={2}>
              <Grid container spacing={7}>
                <CngGridItem xs={12} sm={12}>
                <p><strong>{translatedTextsObject.general}</strong></p>
                <br></br>
                <p>{translatedTextsObject.generalDetails1}</p>
                <p>{translatedTextsObject.generalDetails2}</p>
                </CngGridItem>
        
                <CngGridItem xs={12} sm={12}>
                <p><strong>{translatedTextsObject.terminology}</strong></p>
                <br></br>
                <p>{translatedTextsObject.terminologyDetails1}</p>
    
                </CngGridItem >
    
                <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.disclaimerAndLiability}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.disclaimerAndLiabilityDetails1}</p>
                    <li>{translatedTextsObject.disclaimerAndLiabilityDetails2}</li>
                    <li>{translatedTextsObject.disclaimerAndLiabilityDetails3}</li>
                    <br></br>
                    <p>{translatedTextsObject.disclaimerAndLiabilityDetails4}</p>
                </CngGridItem>
         
                <CngGridItem xs={12} sm={12}>
                  <p><strong>{translatedTextsObject.exceptionToDisclaimer}</strong></p>
                  <br></br>
                  <p>{translatedTextsObject.exceptionToDisclaimerDetails1}</p>
                </CngGridItem>
             
                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.copyright}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.copyrightDetails1}</p>
                    <li>{translatedTextsObject.copyrightDetails2}</li>
                    <li>{translatedTextsObject.copyrightDetails3}</li>
                    
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.trademarks}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.trademarksDetails1}</p>
                  </CngGridItem>


                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.restrictedUse}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.restrictedUseDetails1}</p>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.linkedWebSites}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.linkedWebSitesDetails1}</p>
                    <br></br>
                    <p>{translatedTextsObject.linkedWebSitesDetails2}</p>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.linksFromOtherWebSites}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.linksFromOtherWebSitesDetails1}</p>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.availabilityAndTerminationOfAccess}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.availabilityAndTerminationOfAccessDetails1}</p>
                    <br></br>
                    <p>{translatedTextsObject.availabilityAndTerminationOfAccessDetails2}</p>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>
                    <p><strong>{translatedTextsObject.governingLaw}</strong></p>
                    <br></br>
                    <p>{translatedTextsObject.governingLawDetails1}</p>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={12}>

                  <p>{translatedTextsObject.version}</p>
                  </CngGridItem>

              </Grid>

            </CardContent>
          </Card>
      </Grid>
    </Grid>
  ); 
} 

export default ExternalTermsOfUse; 