import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import ExternalTermsOfUse from './ExternalTermsOfUse'

const useStyles = makeStyles(() => ({
  root: {}
}))

function TermsOfUseView() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title='Home'>
        <ExternalTermsOfUse/>
    </Page>
  )
}

export default TermsOfUseView
